import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import avatarAyman from "images/ayman.jpg";
import avatarPierre from "images/pierre.jpeg";
import avatarHadi from "images/hadi.jpg";
import avatarJorge from "images/jorge.jpg";

import Container from "components/Container";

const mock = [
	{
		name: "Pierre Arbajian, PhD",
		title: "Chief AI Architect",
		avatar: avatarPierre,
		about:
			"Pierre is our Senior Data Scientist and Chief AI Architect, bringing a wealth of experience in all things data and AI. He is passionate about studying and tinkering with LLMs, recognizing that AI holds the potential to revolutionize the world, he strives to make Pillar1 a relevant player in helping clients effectively navigate and adopt this transformative technology.",
	},
	{
		name: "Ayman Hajja, PhD",
		title: "Chief Technology Officer",
		avatar: avatarAyman,
		about:
			"Serves as our Chief Technology Officer, known for developing and deploying cutting-edge, highly complex solutions. With profound expertise in both theory and practice, Ayman leads implementations with precision and unwavering commitment to quality. He firmly believes in a methodical and careful approach, emphasizing thorough development, testing, and implementation with insightful precision for every project and product.",
	},
	{
		name: "Jorge Monteagudo",
		title: "Front End Engineer",
		avatar: avatarJorge,
		about:
			"Jorge is our dedicated Front End Engineer, specializing in creating intuitive and dynamic user interfaces. With extensive experience in HTML, CSS, JavaScript, and modern frameworks like React, Jorge ensures that our web applications are both visually appealing and highly functional. His expertise in responsive design and performance optimization guarantees a seamless user experience across all devices.",
	},
	{
		name: "Hadi Ghattas",
		title: "Back End Engineer",
		avatar: avatarHadi,
		about:
			"Hadi is our skilled Backend Engineer, responsible for designing and maintaining the server-side logic and ensuring seamless integration with frontend components. With deep expertise in various backend technologies, databases, and APIs, Hadi focuses on building robust and scalable solutions. He is dedicated to optimizing system performance and ensuring the reliability and security of our applications, enabling us to deliver high-quality services to our clients.",
	},
];

const WithSimpleCards = () => {
	const theme = useTheme();
	return (
		<Container>
			<Box>
				<Box marginBottom={4}>
					<Typography
						sx={{
							textTransform: "uppercase",
						}}
						gutterBottom
						color={"text.secondary"}
						align={"center"}
						fontWeight={700}
					>
						Our team
					</Typography>
					<Typography
						fontWeight={700}
						variant={"h4"}
						align={"center"}
					>
						Trust the professionals
					</Typography>
				</Box>
				<Grid
					container
					spacing={2}
					alignItems="stretch"
				>
					{mock.map((item, i) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							key={i}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<Box
								component={Card}
								boxShadow={2}
								sx={{
									textDecoration: "none",
									transition: "all .2s ease-in-out",
									"&:hover": {
										transform: `translateY(-${theme.spacing(1 / 2)})`,
									},
									height: "100%",
								}}
							>
								<CardContent>
									<Box
										component={Avatar}
										src={item.avatar}
										margin={"0 auto"}
										height={80}
										width={80}
									/>
									<Box
										marginTop={2}
										display="flex"
										flexDirection={"column"}
										alignItems="center"
									>
										<ListItemText
											primary={item.name}
											secondary={item.title}
										/>
										<Typography
											variant={"subtitle2"}
											color={"text.secondary"}
											mt={1}
										>
											{item.about}
										</Typography>
									</Box>
								</CardContent>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>
		</Container>
	);
};

export default WithSimpleCards;
