import logo from './logo.svg';
import './App.css';
import {ThemeProvider} from "@mui/material/styles";
import getTheme from "theme";
import CssBaseline from "@mui/material/CssBaseline";
import {Main} from "layouts";
import Paper from "@mui/material/Paper";
import {useDarkMode} from "components/Page";

import FullScreenHeroWithSubscriptionForm from "components/FullScreenHeroWithSubscriptionForm";
import FeatureCardsWithColorfullIconsAndLearnMoreLink from "components/FeatureCardsWithColorfullIconsAndLearnMoreLink";
import FeaturesWithCardRepresentation from "./components/FeaturesWithCardRepresentation";
import WithSimpleCards from "./components/WithSimpleCards";

function App() {
    const [themeMode, themeToggler, mountedComponent] = useDarkMode();

    return (
        <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
            <CssBaseline/>
            <Paper elevation={0}></Paper>
            <Main>
                <div>
                    <FullScreenHeroWithSubscriptionForm></FullScreenHeroWithSubscriptionForm>
                    <FeatureCardsWithColorfullIconsAndLearnMoreLink></FeatureCardsWithColorfullIconsAndLearnMoreLink>
                    <WithSimpleCards></WithSimpleCards>
                </div>
            </Main>
        </ThemeProvider>
    );
}

export default App;
