import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {alpha, useTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import {NavItem} from './components';
import Typography from "@mui/material/Typography";

const Topbar = ({onSidebarOpen, pages, colorInvert = false}) => {
    const [pagesList, setPagesList] = useState([])

    const theme = useTheme();
    const {mode} = theme.palette;
    const {
        landings: landingPages, secondary: secondaryPages, company: companyPages, account: accountPages, portfolio: portfolioPages, blog: blogPages,
    } = pages;

    useEffect(() => {

    }, [])


    return (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={1}>

            <Typography
                variant="h4"
                style={{
                    display: "flex",
                    cursor: "pointer",
                    color: "#008000",
                    fontWeight: 700,
                    fontFamily: '"Inter",sans-serif',
                    background: 'linear-gradient(180deg, transparent 82%, rgba(255, 0, 255, 0.3) 0%)'
                }}
                onClick={() => window.location('/')}
            >
                Pillars AI
            </Typography>

            <Box sx={{display: {xs: 'none', md: 'flex'}}} alignItems={'center'}>

                {/*
                <Box marginLeft={4}>
                    <NavItem title={'Landings'} id={'landing-pages'} items={landingPages} colorInvert={colorInvert}/>
                </Box>
                <Box marginLeft={4}>
                    <NavItem size="large" title={'Portfolio'} id={'portfolio-pages'} items={portfolioPages} colorInvert={colorInvert}/>
                </Box>
                */}

                {pagesList.map((currPage, index) => (
                    <Box key={index} marginLeft={4} style={{paddingTop: '10px', paddingBottom: '10px'}}>
                        <NavItem title={currPage.title} href={currPage.href} id={currPage.title} items={currPage.landingPages} colorInvert={colorInvert}/>
                    </Box>
                ))}

            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}}} alignItems={'center'}>
                <Button onClick={() => onSidebarOpen()} aria-label="Menu" variant={'outlined'}
                        sx={{borderRadius: 2, minWidth: 'auto', padding: 1, borderColor: alpha(theme.palette.divider, 0.2),}}>
                    <MenuIcon/>
                </Button>
            </Box>
        </Box>);
};

Topbar.propTypes = {
    onSidebarOpen: PropTypes.func, pages: PropTypes.object, colorInvert: PropTypes.bool,
};

export default Topbar;
