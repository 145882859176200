import React from "react";
import Box from "@mui/material/Box";
import hero from "images/hero.mov";
const Cover = () => {
	return (
		<Box
			sx={{
				height: { xs: "auto", md: 1 },
				overflow: "hidden",
				"& img": {
					objectFit: "fit",
				},
			}}
		>
			<Box
				component={"video"}
				src={hero}
				autoPlay
				loop
				muted
				height={{ xs: "auto", md: 1 }}
				maxHeight={{ xs: 300, md: 1 }}
				width={1}
				maxWidth={1}
				sx={{
					objectFit: "cover",
					objectPosition: "center  ", // Adjust this value to skew the image to the left
				}}
			/>
		</Box>
	);
};

export default Cover;
