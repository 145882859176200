import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();
    const {mode} = theme.palette;

    useEffect(() => {

    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={1} flexDirection={{xs: 'column', sm: 'row'}}>
                    <Box display={'flex'} title="theFront" width={200}>
                        <Typography variant="h6" style={{
                            display: "flex", cursor: "pointer",
                            color: "#008000", fontWeight: 700,
                            fontFamily: '"Inter",sans-serif', background: 'linear-gradient(180deg, transparent 82%, rgba(255, 0, 255, 0.3) 0%)'
                        }} onClick={() => window.location = '/'}>
                            Pillar1
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Typography align={'center'} variant={'subtitle2'} color="text.secondary" gutterBottom>
                    &copy; Pillar1. 2023. All rights reserved
                </Typography>
                <Typography align={'center'} variant={'caption'} color="text.secondary" component={'p'}>
                    When you visit or interact with our sites, services or tools, we or
                    our authorised service providers may use cookies for storing
                    information to help provide you with a better, faster and safer
                    experience and for marketing purposes.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Footer;
