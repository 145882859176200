import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

export const useDarkMode = () => {
    const [themeMode, setTheme] = useState('light');
    const [mountedComponent, setMountedComponent] = useState(false);

    const setMode = (mode) => {
        try {
            window.localStorage.setItem('themeMode', mode);
        } catch {
            /* do nothing */
        }

        setTheme(mode);
    };

    const themeToggler = () => {
        themeMode === 'light' ? setMode('dark') : setMode('light');
    };

    useEffect(() => {
        try {
            const localTheme = window.localStorage.getItem('themeMode');
            localTheme ? setTheme(localTheme) : setMode('light');
        } catch {
            setMode('light');
        }

        setMountedComponent(true);
    }, []);

    return [themeMode, themeToggler, mountedComponent];
};

export default function Page({children}) {
    return (
        <></>
    );
}

Page.propTypes = {
    children: PropTypes.node.isRequired,
};
